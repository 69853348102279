.gc-carousel {
  display: block;
  width: 100%;
  height: 500px;
}

.gc-carousel-item {
  display: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gc-carousel-item.active {
  display: block;
}
