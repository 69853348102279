body {
  background-color: #eaedf4;
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  padding-top: 85px;
}

header {
  padding: 10px 16px;
  background-color: #00aeef;
  color: #fff;
  position: fixed;
  height: 85px;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #000;
}

header ul {
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
}

header ul li {
  display: inline-block;
  margin-left: 24px;
}

header ul li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

header ul li a:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #fff;
}

.card-hz {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #fff;
}

.card-hz .card-hz-body {
  padding: 15px;
}

.card-hz img {
  border-top-left-radius: calc(0.25rem - 1px);
}

@media (max-width: 576px) {
  body {
    padding-top: 0;
  }

  header {
    position: relative;
    height: auto;
  }

  header .logo-col a {
    display: block;
    margin: auto;
    text-align: center;
  }

  header .nav-col {
    margin: 0 auto;
  }

  header .nav-col ul {
    display: flex;
    flex-direction: row;
    justify-items: center;
    flex-wrap: wrap;
    margin: 8px 24px 8px 0;
  }

  header .nav-col ul li {
    flex: 1;
    float: none;
    text-align: center;
    margin: 0 8px;
  }
}

.blue-section {
  background-image: url("https://cannon-studio.s3.amazonaws.com/globalconnexx/donation-bg/donations-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px 0;
  color: #fff;
}

.section-heading {
  border-bottom: 1px solid #fff;
  margin-bottom: 24px;
  padding-bottom: 12px;
}

.become-volunteer {
  background-color: #00aeef;
  background-image: url(https://cannon-studio.s3.amazonaws.com/globalconnexx/volunteer-bg.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 45px 40px;
  color: #fff;
  margin-top: 28px;
  max-height: 452px;
}

.become-volunteer h2 {
  margin: 12px 0;
}

.footer-container {
  background-color: #d5d8de;
  background-image: url(https://cannon-studio.s3.amazonaws.com/globalconnexx/recent-post-bg.jpg);
  background-size: cover;
  padding: 48px 0;
}

.footer-info {
  margin-top: 12px;
}

.footer-info .svg-inline--fa {
  font-size: 2em;
  color: #00aeef;
  float: left;
  margin-right: 12px;
}
